import Vue from 'vue'
import Router from 'vue-router'

import mainPage from '@/views/mainPage'
import membersPage  from '@/views/membersPage'
import friendsPage  from '@/views/friendsPage'

import loginPage  from '@/views/loginPage'

import reservationFormPage from '@/views/reservationFormPage'
import personalSettingsPage from '@/views/personalSettingsPage'
import calendarPage from '@/views/calendarPage'
import reservationsListPage from '@/views/reservationsListPage'
import documentsPage from '@/views/documentsPage'
import reservationOverviewPage from '@/views/reservationOverviewPage'
import passwordChangePage from '@/views/passwordChangePage'
import passwordResetPage from '@/views/passwordResetPage'


import signUpPage from '@/views/signUpPage' //deprecated

import testPage from '@/views/testPage'

const fb = require('./firebaseConfig.js') 
const personDb = require('./db-persons');

Vue.use(Router)

var router = new Router({
  mode: 'history',

  routes: [
    {
      path: "/test",
      name: "Test",
      component: testPage
    },
    {
      path: '/login',
      name: 'Login',
      component: loginPage
    },

    {
      path: '/signup',
      name: 'Signup',
      component: signUpPage
    },

    {
      path: '/passwordReset',
      name: 'PasswordReset',
      component: passwordResetPage
    },

    {
      path: '/',
      name: 'Home calendar',
      component: calendarPage,
      meta: {
        requiresAuth: true
      } 
    },
    {
      path: '/calendar',
      name: 'Calendar',
      component: calendarPage,
      meta: {
        requiresAuth: true
      } 
    },
    {
      path: '/home',
      name: 'Home',
      component: mainPage,
      meta: {
        requiresAuth: true
      } 
    },
    {
      path: '/personalSettings',
      name: 'PersonalStings',
      component: personalSettingsPage,
      meta: {
        requiresAuth: true
      } 
    },
    {
      path: '/passwordChange',
      name: 'PasswordChange',
      component: passwordChangePage,
      meta: {
        requiresAuth: true
      } 
    },
    {
      path: '/members',
      name: 'Members',
      component: membersPage,
      meta: {
        requiresAuth: true
      } 
    },
    {
      path: '/friends',
      name: 'Friends',
      component: friendsPage,
      meta: {
        requiresAuth: true
      } 
    },
    {
      path: '/reservations',
      name: 'Reservations',
      component: reservationsListPage,
      meta: {
        requiresAuth: true
      } 
    },
    {
      path: '/reservations/:id',
      name: 'ReservationDetail',
      component: reservationFormPage,
      meta: {
        requiresAuth: true
      } 
    },
    {
      path: '/documents',
      name: 'Documents',
      component: documentsPage,
      meta: {
        requiresAuth: true
      } 
    },
    {
      path: '/reservationOverview',
      name: 'ReservationOverview',
      component: reservationOverviewPage,
      meta: {
        requiredAuth: true
      }
    }
  ]
})


router.afterEach((to, from) => {
   const currentUser = fb.getCurrentUser().email
   personDb.storeUserActivity(currentUser, "pageView", to.path)
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  // const currentUser = firebase.auth().currentUser
  const currentUser = fb.getCurrentUser()

  if (requiresAuth && !currentUser) {
      next('/login')
  } else if (requiresAuth && currentUser) {
      next()
  } else {
      next()
  }
})

export default router;